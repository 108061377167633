<template>
    <div>
        <div :style="styleObject" class="">
            <div class="container">
                <component
                    ref="product"
                    v-bind:is="currentComponent"
                    :categories="categories"
                    :products="products"
                    :color="color"
                    @onClickItem="goToNewSubcategory" 
                >
                </component>
            </div>
        </div>
    </div>
    </template>
   
    <script>
    import {
        mapGetters
    } from 'vuex'
    import _ from 'lodash'
    import Breadcrumb from '@/components/common/BreadcrumbComponent'
    //import EstoreSearch from '@/components/EstoreSearch'
    import CategoryList from '@/components/category/Homecate'
    import ProductsListView from '@/components/estore/ProductListView.vue'
    export default {
        name: 'pcategory-page',
        components: {
            Breadcrumb,
            CategoryList,
            ProductsListView
        },
        data() {
            return {
                newsubcategories: [],
                search: '',
                activeCategory: null,
                //products: []
            }
        }, //end of data
        props: {
    color: {
      type: String,
      default: 'red'
    }
  },
        computed: {
            ...mapGetters({
                categories: 'category/GET_GB_CATEGORIES',
            }),
            lastPage(){
                return this.$store.state.product.lastPage
            },
            currentPage(){
                return this.$store.state.product.currentPage
            },
            isLoading(){
                return this.$store.state.product.loader
            },
            products() {
                return this.$store.state.product.filteredProducts;
            },
            currentComponent() {
                return this.search.length !== 0 ? 'ProductsListView' : 'CategoryList'
            },
            styleObject() {
      return {
        color: this.color
      }
    },
        },
        methods: {
            goToNewSubcategory(categoryId) {
                this.activeCategory = categoryId ? categoryId : 1
                this.$router.push({
                    name: 'products-list',
                    query: {
                        cat_id: categoryId
                    }
                })
            },
            betterSearch: _.debounce(function () {
                // this.$refs.product.resetPageNumber();
                let query = '?search=' + this.search;
                this.$store.dispatch('product/ACT_FILTER_PRODUCTS', query);
            }, 1500),
            onKeyUp() {
                this.$refs.product.startLoading();
            },
            onClickPageNumber() {
                let nextPage = this.currentPage + 1;
                let query = '?search=' + this.search + '&page=' + nextPage;
                this.$store.dispatch('product/ACT_FILTER_PRODUCTS', query);
            },
            intersecting(isVisible){
                if(!isVisible) { return }
                if(!this.isLoading) {
                    this.onClickPageNumber();
   
                }
            }
        },
        mounted() {
            this.$store.dispatch('category/ACT_GB_CATEGORIES_LIST', 'gb-product')
            this.activeCategory =  this.$route.query.cat_id
            //this.$store.dispatch('product/ACT_FILTER_PRODUCTS');
        },
    }
    </script>
   
    <style scoped>
   
    </style>