<template>
  <div  class="container" style="background: transparent; position: relative; z-index:1;">
    <div class="row">
      <v-sheet
        class="mx-auto r-slide col-lg-2  "
        :min-width="$vuetify.breakpoint.xs ? '40%':$vuetify.breakpoint.sm ? '40%' :$vuetify.breakpoint.md ? '95%' :$vuetify.breakpoint.lg ? '100%' :$vuetify.breakpoint.xl ? '100%' : '100%'"
        style="background: transparent; "
      >
        <v-slide-group
          active-class="success"
          max="1"
          show-arrows="always"
          style="background: transparent"
          class="col-lg-3 col-sm-6 col-xs-12"
         
        >
          <template v-slot:prev="{ on, attrs }">
            <img
              src="/img/new-image/left.svg"
              class=""
              style="height: 48px; width: 48px; padding-right: 20px ; margin-top: 20px;"
            />
          </template>
          <template v-slot:next="{ on, attrs }">
            <img
              src="/img/new-image/right.svg"
              style="height: 48px; width: 48px; padding-left: 20px; margin-top: 20px;;"
            />
          </template>
          <div v-if="categories.length<1">
       
          <v-slide-item
          v-for="n in 15"
          :key="n"
          style="margin-top: 10px;"   
          class=""      
           >
           <skeleton-box
                  width="150px"
                  height="100px"
                  class="col-3 ml-3 "
                  style="border-radius:10%; margin-bottom: 50px;"  
                />
              
      </v-slide-item>
    
    </div>
       <template v-else>
          <v-slide-item
            v-for="item in categories" :key="item.id"
            class="row my-slide mb-2"
          >
          
            <v-card
              @click="onClickItem(item.categories_id)"
              style="width:140px; height: 145px; border: none; box-shadow: none;"
              :ripple="false"
              :class="['cat-card',{'hello' : CurrentActive == item.categories_id}]"
            
            >
            <!-- :src="item.thumbnail" -->
              <div  class=" bor " style="background-image: url('/img/product/2.svg');" >
              <div class=" gradient-inside" >
                   <img class="hel px-2 py-2" :src="item.thumbnail"  />
               </div>
            </div>
            <div :style="{ color: color }" class="mt-2 category-name ">{{ item.name }}</div>
           
            </v-card>
          </v-slide-item>
        </template>
        </v-slide-group>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import SkeletonBox from '../common_components/SkeletonBox.vue';
export default {
  name: 'hero',
  components: {SkeletonBox},
  data(){
return {
loading:true,
loddesign: 20+'px',
img:"/img/product/2.svg",
}
  },
  props:{
      categories:{required:true},
      color:{
        type: String,
      default: '#2F4F4F'
      }
  },
  computed: {
  CurrentActive() {
    if (this.$route.name === 'products-list') {
      return this.$route.query.cat_id || 1 ;
    }
    // Return a default value or handle the case where CurrentActive is not needed for other pages
    return null;
  }
},

  methods: {
      onClickItem(catId){
          this.$emit('onClickItem', catId)
      },
      resetPageNumber() {},
      startLoading() {},
  },
   async mounted(){
      this.loading=false
      
  }
}
</script>
 <style scoped>
@font-face {
    font-family: 'Quicksand', sans-serif;
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'CastorTwoW01-Regular';
    src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
}
 * {
  margin: 0;
}
/* .main-sec {


 
} */
.my-slide{
  margin-top: 25px !important;
  margin-left: 25px !important ;
  background:transparent;
  border-radius: 10%;
             
}
.bor{
   border-radius: 10%;
   margin-left: 10px;
    margin-top: 10px;
  /* border-image: repeating-linear-gradient(30deg, #4d9f0c, #9198e5, #4d9f0c 20px) 60; */
}

.gradient-inside {
  border-radius: 10%;
  width: 125px;
  height: 100px;
}

.hel{
    object-fit: fill;
    margin:auto;
    height:100%;
    width: 100%;
    border-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -2px;
}

.category-name{
  text-align: center;
  font-size: 13px;
  font-family: Quicksand;
  font-weight: 650;
  text-transform: uppercase;
  margin-left: 5px;
  width: 130px;
  color: #2F4F4F;
  z-index: 1;
}
 .hello{
  background-color: rgba(255, 255, 0, 0.264) !important;
 
  pointer-events: none;
  z-index: -1;
  
}
.v-card--link:before{
  content: unset;
}


/* .custom-ripple::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 160px;
  top: -8%;
  left: -10%;
  background-color: rgba(255, 255, 0, 0.264);
  transform: scale(0);
  transition: transform 0.3s ease-out;
  pointer-events: none;
  z-index: -1;
  
}
.custom-ripple::before{
  background: transparent;
} */

/* .custom-ripple:focus::after,
.custom-ripple:active::after {
  transform: scale(1);
} */
.cat-card{
  margin-left: -20px;
}

@media (min-width: 1904px){
.container {
    max-width: 100% !important;
}}
@media (min-width: 800px) and (max-width: 1860px)   {
 .container {
  max-width: 100%;
  
}
 }
 @media only screen and (max-width: 769px) {

  .container {
    max-width: 100%;
   margin-top: -50px;
  }
  
}
/* @media (min-width:350px) and (max-width: 375px) {
  .cat-card{
    width: 140px !important;
  }
  .my-slide {
    margin-left: 20px !important ;
  }
  
  .gradient-inside{
    width: 110px;
    height: 90px;
    margin-left: 2px;
  }
  .category-name{
    margin-left: -3px;
    font-size: 10px;
  }
} */
@media (min-width:376px) and (max-width: 767px) {
   .hello{
    width: 96px !important;
    height: 100px !important;
    margin-left: 3px;
   }
  .cat-card{
    margin-left: 5px !important;
    width: 105px !important;
    height: 110px !important;
   
  }
  .my-slide {
    margin-left: 0px !important ;
  }
  .gradient-inside{
    width: 89px;
    height: 70px;
    margin-left: 0px !important;
    padding-left: px;
   
  }

  .category-name{
    font-size: 10px;
    margin-left: -12px !important;
    text-align: center;
    word-wrap: break-word;
  }

  .bor{
    margin-left: 10px;
  }
}
@media  (max-width:375px) {
  .hello{
    width: 96px !important;
    height: 90px !important;
    margin-left: 3px;
   }
  .cat-card{
    margin-left: 5px !important;
    width: 80px !important;
    height: 100px !important;
   
  }
  .my-slide {
    margin-left: 0px !important ;
  }
  .gradient-inside{
    width: 75px;
    height: 60px;
    margin-left: 0px !important;
    padding-left: -10px !important;
    padding-top: -10px !important;
   
  }

  .category-name{
    width:55px;
    height: 20px;
    font-size: 8px;
    margin-left: 15px !important;
    text-align: center;
    
  }

  .bor{
    margin-left: 4px;
  }
}
</style>