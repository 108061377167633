<template>
   
    <div class="container">
        <div class="mobile-vi">
    <carousel
      :products="products"
      ref="carousel"
      :responsive="{ 0: { items: 1, nav: false, dots: true } }"
      :autoplay="false"
    >
    <div  v-for="item in products" :key="item.products_id" class="col-12  p-1 mt-3">
            <router-link class="readmore ml-4" 
                    :to="{name:'newestore.newproduct.show',params:{id:item.products_id},query:{name:replaceSpace(item.name)}}">  
            <div class=" card-design m-auto" >
                <div class="gradient-inside">
                    <img v-if="item.images.length > 0"  style="" alt="" :src="item.images[0].path" class="text-center">
                    <div v-if="item.available == 0" class="overlay">
                        <div class="overlay-text">Out of Stock</div>
                    </div>
                </div>
            </div>
            </router-link>
           
            <p class="text-center product-name mx-auto mt-4">
                <router-link class="readmore" 
                    :to="{name:'newestore.newproduct.show',params:{id:item.products_id},query:{name:replaceSpace(item.name)}}">   
                    {{ item.name}}
                </router-link>
            </p>
            <hr class="ml-4 mr-3" style="margin-top: -15px; margin-bottom: 14px; height: 1px;background-color: #000;border: none;"/>
    
            <div class="text mx-auto pl-1" style="width:max-content;">
                <div style="display: flex;">
                    <div class="text-center" style="width: 70px;display: flex; margin-top: 3px;">
                        <div class="" style="display: flex;"><img src="/img/product/0001.png" style="height:13px; width: 10px;"> <div style="margin-top: -3px; margin-left: 2px;">{{ item.weight }}</div>
                        <div v-if="item.unit !== null" style="margin-top: -3px; margin-left: 2px;">{{ item.unit.name }}</div> </div>
                    </div> 
                    
                    <!--<span v-if="item.variable_weight == 1">(final cost based on weight)</span>-->
                    <div v-if="item.promo_banners" class="ml- mr-2">

                        <!-- <span v-if="item.promo_banners.length <= 0 || !item.promo_banners" style=""> <span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</span> -->
                        <template  v-if="item.promo_banners.length > 0">
                        <span> <del><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</del></span>
                        <span  v-if="item.promo_banners[0].is_discount_percentage==0"  style="font-size: 13px; font-weight: bold; padding-right: 10px;display: block;"> 
                            <strong class="text-danger">৳ </strong> {{ (item.price - item.promo_banners[0].discount).toFixed(2) }}</span>
                        <span  v-if="item.promo_banners[0].is_discount_percentage==1"   style="font-size: 13px; font-weight: bold; padding-right: 10px;display: block;"> 
                            <strong class="text-danger">৳ </strong> {{ (item.price - (item.price * item.promo_banners[0].discount)/ 100 ).toFixed(2) }}</span>    
                        </template>
                        <template v-else>
                        <!-- <span  style="font-size:16px; padding-left:8px; padding-right: 10px;"> <strong class="text-danger">৳ </strong> <s>{{ item.price }}</s></span> -->
                            <!-- <span><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</span> -->
                            <template v-if="item.discount == 0">
                                <span><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</span>
                           </template>
                           <template v-else>
                            <span> <del><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</del></span>
                            <span  v-if="item.is_discount_percentage==0"  style="font-size: 13px; font-weight: bold; padding-right: 10px;display: block;"> 
                                <strong class="text-danger">৳ </strong> {{ (item.price - item.discount).toFixed(2) }}</span>
                            <span  v-if="item.is_discount_percentage==1"   style="font-size: 13px; font-weight: bold;padding-right: 10px;display: block;"> 
                                <strong class="text-danger">৳ </strong> {{ (item.price - (item.price * item.discount)/ 100 ).toFixed(2) }}</span> 
                        <!-- <span  class="discount" style="font-size:15px;"> <strong class="text-danger">৳ </strong> {{ (item.price - item.discount).toFixed(2) }}</span> -->
                        </template>
                        </template>
                        </div>
                   
                    
                    <div style="margin-top:-4px;">
                        <button :disabled="item.available == 0" @click="cartAdded(item)" class="btn text-white ml-md-1 px-lg-3 "><img src="/img/product/cart-icon-2.png" style="height:20px;width:20px; font-weight: bolder; " class="mt-1 mt-lg-0"><span class="ml-lg-1 mt-1">Add to Cart</span></button>
                    </div>
                </div> 
            </div>
            
        </div>
    </carousel>
    <v-snackbar v-model="snackbar">
            {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
        </v-snackbar>
        <!-- snackbar -->
  </div>

    <div  class="row mt-">
        <div  v-for="item in products" :key="item.products_id" class="col-12  p-1 mt-3">
            <router-link class="readmore ml-4" 
                    :to="{name:'newestore.newproduct.show',params:{id:item.products_id},query:{name:replaceSpace(item.name)}}">  
            <div class=" card-design m-auto" >
                <div class="gradient-inside">
                    <img v-if="item.images.length > 0"  style="" alt="" :src="item.images[0].path" class="text-center">
                    <div v-if="item.available == 0" class="overlay">
                        <div class="overlay-text">Out of Stock</div>
                    </div>
                </div>
            </div>
            </router-link>
           
            <p class="text-center product-name mx-auto mt-4">
                <router-link class="readmore" 
                    :to="{name:'newestore.newproduct.show',params:{id:item.products_id},query:{name:replaceSpace(item.name)}}">   
                    {{ item.name}}
                </router-link>
            </p>
            <hr class="ml-4 mr-3" style="margin-top: -15px; margin-bottom: 14px; height: 1px;background-color: #000;border: none;"/>
    
            <div class="text mx-auto pl-1" style="width:max-content;">
                <div style="display: flex;">
                    <div class="text-center" style="width: 70px;">
                        <span class=""><img src="/img/product/0001.png" style="height:13px; width: 10px; margin-top:-2px;"> {{ item.weight }}</span>
                        <span v-if="item.unit !== null">{{ item.unit.name }}</span> 
                    </div> 
                    
                    <!--<span v-if="item.variable_weight == 1">(final cost based on weight)</span>-->
                    <div v-if="item.promo_banners" class="ml- mr-2">

                        <!-- <span v-if="item.promo_banners.length <= 0 || !item.promo_banners" style=""> <span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</span> -->
                        <template  v-if="item.promo_banners.length > 0">
                        <span> <del><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</del></span>
                        <span  v-if="item.promo_banners[0].is_discount_percentage==0"  style="font-size: 13px; font-weight: bold; padding-right: 10px;display: block;"> 
                            <strong class="text-danger">৳ </strong> {{ (item.price - item.promo_banners[0].discount).toFixed(2) }}</span>
                        <span  v-if="item.promo_banners[0].is_discount_percentage==1"   style="font-size: 13px; font-weight: bold; padding-right: 10px;display: block;"> 
                            <strong class="text-danger">৳ </strong> {{ (item.price - (item.price * item.promo_banners[0].discount)/ 100 ).toFixed(2) }}</span>    
                        </template>
                        <template v-else>
                    <!-- <span  style="font-size:16px; padding-left:8px; padding-right: 10px;"> <strong class="text-danger">৳ </strong> <s>{{ item.price }}</s></span> -->
                            <!-- <span><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</span> -->
                            <template v-if="item.discount == 0">
                                <span><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</span>
                           </template>
                           <template v-else>
                            <span> <del><span class="text " style="color: #C0272D;">৳</span>{{ item.price }}</del></span>
                            <span  v-if="item.is_discount_percentage==0"  style="font-size: 13px; font-weight: bold; padding-right: 10px;display: block;"> 
                                <strong class="text-danger">৳ </strong> {{ (item.price - item.discount).toFixed(2) }}</span>
                            <span  v-if="item.is_discount_percentage==1"   style="font-size: 13px; font-weight: bold;padding-right: 10px;display: block;"> 
                                <strong class="text-danger">৳ </strong> {{ (item.price - (item.price * item.discount)/ 100 ).toFixed(2) }}</span> 
                    <!-- <span  class="discount" style="font-size:15px;"> <strong class="text-danger">৳ </strong> {{ (item.price - item.discount).toFixed(2) }}</span> -->
                        </template>
                    </template>
                    </div>
                    
                    <div style="margin-top:-4px;">
                        <button :disabled="item.available == 0" @click="cartAdded(item)" class="btn text-white ml-md-1 px-lg-3 "><img src="/img/product/cart-icon-2.png" style="height:20px;width:20px; font-weight: bolder; " class="px-"><span class="ml-lg-1 mt-1">Add to Cart</span></button>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- snackbar -->
        <v-snackbar v-model="snackbar">
            {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
        </v-snackbar>
        <!-- snackbar -->
    </div>
    </div>
</template>

<script>

// import SkeletonBox from './common_components/SkeletonBox.vue';
import carousel from "vue-owl-carousel";
export default {
    name: 'NewProductListView',
    components:{carousel},
    data () {
        return {
            loading: true,
            snackbar: false,
            text: '',
            img:"/img/product/image.png",
        }
    },
    props: {
        products: {required: true}
    },
    watch:{
         products: function() {
            this.loading = false;
         }
    },
    
    methods: {
        replaceSpace(name){
            return name.replace(/[\W_]/g, "-")
        },
        cartAdded(product){
                const newProduct = {...product}
                if(newProduct.promo_banners[0] && newProduct.promo_banners[0].discount) {
                    newProduct['discount'] = newProduct.promo_banners[0].discount
                    newProduct['is_discount_percentage'] = newProduct.promo_banners[0].is_discount_percentage
                } else {
                    newProduct['discount'] = product['discount']
                    newProduct['is_discount_percentage'] = product['is_discount_percentage']
                }
                
                this.productId=newProduct.product_id;
                this.$store.commit('addTCart',newProduct);
                this.snackbar = true,
                this.text = "Added to cart"
                localStorage.setItem('cart',JSON.stringify(this.$store.getters.cart_Info));
                // this.notify()
            },
    }, //end of methods
    
}
</script>

<style scoped>
@font-face {
    font-family: 'Quicksand', sans-serif;
    src: url('/fonts/Quicksand-VariableFont_wght.ttf');
}

.readmore{
font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 14px;
letter-spacing: normal;
/* identical to box height, or 125% */

text-align: center;
line-height: 20px;

/* Black */

color: #231F20;
    }
    
    
@media screen and (max-width: 767px) {
    .mobile-vi{
        display: block !important; 
    }
    
    .mt-{
        display: none ;
    }
    /* Change the color and size of the scroll sign */
    ::-webkit-scrollbar {
        color: #e54c2a;
        scrollbar-width: none;
        height: 2px;  
}
.card-design{
    width: 320px;
  height: 280px;
   
}
.btn{
    display: flex;
}



}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .mobile-vi{
        display: none;
    }
    
    .col-12{
        max-width: 50%;
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .mobile-vi{
        display: none;
    }
    .col-12{
        max-width: 33%;
    }
}
@media screen and (min-width: 1201px) {
    .mobile-vi{
        display: none;
    }
    .col-12{
        max-width: 25%;
    }
}
.gradient-inside {
  width: 100%;
  height: 100%;
  background: transparent;
  /* background: linear-gradient(312.02deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%); */
  border-radius: 28px;
  padding: 1px;

}
.gradient-inside img{
 
  /* background: linear-gradient(312.02deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%); */
  border-radius: 40px;
  padding: 7px;
  object-fit: fill;
   display:block;
    margin:auto;
    height:100%; 
    width: 100%;

}
.card-design {
  margin: auto;
  width: 270px;
  height: 220px;
  border-radius: 40px;
  max-width: 324px; 
  max-height: 340px; 
  padding-top:1px; 
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 6px solid black;
  border-bottom: 7px solid black;
  }

    .product-name{
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
   font-size: 14px !important;
    line-height: 14px;
      /* width: 270px; */
      text-align: center;
      
    }
    .product-name:hover a{
      color: #e54c2a;
    }
    .overlay{
        display: none;
        position: absolute;
        background-color: rgba(40,40,40,.50);
        height: 100%;
        width: 50%;
        top: 0;
    }
    .product:hover .overlay{
        display: inline;
    }
    .overlay-text{
        font-size: 20px;
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        color: wheat;
        transform: translate(-50%, -50%);
    }
    /* .holder-div{
        margin: auto;
        height: 240px;
        width: 240px;
        padding: 10px;
        border: 1.5px solid black;
        box-shadow: 0 0 10px 0px gray;
        border-radius: 50%;
        position: relative;
        text-align: center;
        overflow: hidden;
    }
    .holder-div img{
        border-radius: 10%;
    } */
   
    .discount{
        margin-left: -44px;
    }
    .text{
        font-family: 'Quicksand';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 20px;
color: #231F20;
    }
    .btn {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        border: none;
        letter-spacing: normal;
        color: white;
        text-align: center;
        text-decoration: none;
        border-radius: 6px;
        width: 125px;
        height: 28px;
        background: rgb(199, 9, 9); 
}
</style>