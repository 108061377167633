<template>
    <div class="row">
        <div class="col-md-12">
            <div class="title mt-5 mb-5">
                <h5 style="margin-bottom: 30px !important; font-weight:bold" class="text-center">Our Products</h5>
            </div>
            <div class="row">
                <div v-if="this.$store.state.product.loader" class="row">
              <div class="col-lg-3 col-md-4 col-xs-12 response" v-for="n in 15" :key="n">
                
               
                <skeleton-box
                v-if="this.$store.state.product.loader"
                    width="270px"
                    height="253px"
                    style="border-radius:10%;"   
                  />

              </div>
            </div>

                <div class="col-md-12" v-else>
                    <div class="row">
                        <div 
                            v-for="item in products" 
                            :key="item.products_id" 
                            class="col-md-3 mb-4 product"
                        >
                            <router-link class="readmore" :to="{name:'newestore.newproduct.show',params:{id:item.products_id},query:{name:replaceSpace(item.name)}} ">
                                <div class="holder-div">
                                    <img v-if="item.images.length > 0" style="object-fit: cover; display:block; margin:auto;height:100%; width: 100%" alt="" :src="item.images[0].path">
                                    <div v-if="item.available == 0" class="overlay">
                                        <div class="overlay-text">Out of Stock</div>
                                    </div>
                                </div>
                            </router-link>
                            <p class="text-center product-name mt-3">
                                <router-link class="readmore" :to="{name:'newestore.newproduct.show',params:{id:item.products_id},query:{name:replaceSpace(item.name)}}">
                                    {{ item.name}}
                                </router-link>
                            </p>
                            <p class="text-center">{{ item.weight }}
                                <span v-if="item.unit !== null">{{ item.unit.name }}</span>
                                <span v-if="item.variable_weight == 1">(+/-)</span>
                                <br>
                                <span v-if="item.variable_weight == 1">(final cost based on weight)</span>
                                <br>
                                <span v-if="item.discount == 0" style="font-weight:bold;font-size:20px;">৳ {{ item.price }}</span>
                                <template v-if="item.discount != 0">
                                    <span style="font-weight:bold; font-size:20px; margin-right:5px"> ৳ {{ item.price - item.discount }}</span>
                                    <span style="font-weight:bold"> <del style="color:#8c8c8c">৳ {{ item.price }}</del></span>
                                </template>
                            </p>
    
                            <button :disabled="item.available == 0" @click="cartAdded(item)" style="background: #e54c2a; border-radius: 0" class="btn btn-block text-white">Add to cart</button>
                        </div>
                    </div>
    
                    <!-- objerver for product -->
                        <div v-if="products.length" v-observe-visibility="handleScrolledToBottom"></div>
                    <!-- objerver for product -->
    
                </div>
            </div>
        </div>
        <!-- snackbar -->
        <v-snackbar v-model="snackbar">
            {{ text }}
            <v-btn color="pink" text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
        <!-- snackbar -->
    </div>
    </template>
    
    <script>
        import {mapMutations} from 'vuex';
        import SkeletonBox from './../common_components/SkeletonBox.vue'; 
    export default {
        name: 'NewProductListView',
        data() {
            return {
                snackbar: false,
                loading:true,
                text: '',
                page: 0,
            }
        },
        components:{SkeletonBox  },
        props: {
            products: {
                required: true
            }
        },
    
        methods: {
            ...mapMutations({
                startLoader: 'product/SET_LOADING'
            }),
    
            replaceSpace(name) {
                return name.replace(/[\W_]/g, "-")
            },
            cartAdded(product) {
                this.productId = product.product_id;
                this.$store.commit('addTCart', product);
                this.snackbar = true,
                    this.text = "Added to cart"
                localStorage.setItem('cart', JSON.stringify(this.$store.getters.cart_Info));
                // this.notify()
            },
            handleScrolledToBottom(isVisible) {
                this.$emit('intersecting', isVisible)
            },
            // next(page) {
            //     console.log(page);
            //     this.$emit('onClickPageNumber', page);
            // },
            // resetPageNumber() {
            //     this.page = 1;
            // },
            startLoading() {
                this.startLoader(true);
            },
        }, //end of methods
        async mounted(){
            this.loading=false
        }
    }
    </script>
    
    <style scoped>
    .readmore {
        font-size: 16px;
    }
    
    .overlay {
        display: none;
        position: absolute;
        background-color: rgba(40, 40, 40, .50);
        height: 100%;
        width: 100%;
        top: 0;
    }
    
    .product:hover .overlay {
        display: inline;
    }
    
    .overlay-text {
        font-size: 20px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        color: wheat;
        transform: translate(-50%, -50%);
    }
    
    .holder-div {
        position: relative;
        height: 270px;
        width: 270px;
        margin: auto;
        overflow: hidden;
    }
    
    .product-name {
        font-family: myThirdFont;
        line-height: 20px;
        height: 30px;
    }
    
    .product-name:hover a {
        color: #e54c2a;
    }
    </style>
    